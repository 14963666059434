import { useMemo } from "react";
import { useFirestore } from "hooks/firestore/v2/useFirestore";
import { creditCard } from "hooks/firestore/v2/models/creditCard";
import { parseISO, subYears } from "date-fns";

const randomDateInPast = subYears(new Date(), 10).toISOString();

const useCreditCards = () => {
  const {
    data: creditCards,
    isLoading,
    error,
  } = useFirestore("/users/{userId}/creditCards", creditCard.array());

  const sortedByLastSuccessfulDepositAt = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return (creditCards || []).slice().sort((a, b) => {
      const aDate = parseISO(a.lastSuccessfulDepositAt || randomDateInPast);
      const bDate = parseISO(b.lastSuccessfulDepositAt || randomDateInPast);

      return aDate.getTime() - bDate.getTime();
    });
  }, [creditCards]);

  return {
    creditCards: sortedByLastSuccessfulDepositAt,
    isLoading,
    error,
  };
};

const useCreditCardWithDeposit = () => {
  const { creditCards, isLoading } = useCreditCards();

  if (isLoading) return;

  return creditCards.find((card) => (card.successfulDepositCount || 0) > 0);
};

const useIsQuickDepositAvailable = () => {
  const cardWithDeposit = useCreditCardWithDeposit();

  return cardWithDeposit !== undefined;
};

type CreditCard = Exclude<
  ReturnType<typeof useCreditCards>["creditCards"][0],
  undefined
>;

export {
  useCreditCards,
  useCreditCardWithDeposit,
  useIsQuickDepositAvailable,
  type CreditCard,
};
