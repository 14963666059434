import { useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import { useCollectionData, useDocumentData, usePermissions } from "hooks";
import { collection, query, where, doc } from "firebase/firestore";
import { createRacingEventsConverter } from "./useRace";
import type { RaceEventType } from "./RacingTypes";
import {
  type RaceMeetingType,
  createRaceMeetingsConverter,
} from "sections/Betting/Race/hooks/useRaceMeetings";
import { useCampaigns } from "hooks/firestore/v2/user/useCampaigns";
import type { Maybe } from "types/utilities";

export const useRaceMeeting = (
  meetingId?: string,
): [Maybe<RaceMeetingType>, Maybe<RaceEventType[]>, boolean] => {
  const db = getFirestore();
  const { codes: userCampaigns } = useCampaigns();
  const permissions = usePermissions();

  const raceMeetingsConverter = useMemo(
    () => createRaceMeetingsConverter(userCampaigns, permissions),
    [userCampaigns, permissions],
  );
  const raceEventsConverter = useMemo(
    () => createRacingEventsConverter(userCampaigns, permissions),
    [userCampaigns, permissions],
  );

  const raceMeetingsRef = useMemo(() => {
    if (!meetingId) return null;

    return doc(db, "raceMeetings", meetingId).withConverter(
      raceMeetingsConverter,
    );
  }, [meetingId]);

  const racesEventsRef = useMemo(() => {
    if (!meetingId) return null;

    return collection(db, "racingEvents").withConverter(raceEventsConverter);
  }, [meetingId]);

  const [raceMeeting, raceMeetingLoading] = useDocumentData(raceMeetingsRef);
  const [races, racesLoading] = useCollectionData(
    meetingId && racesEventsRef
      ? query(
          racesEventsRef,
          where("meetingId", "==", meetingId),
          where("visible", "==", true),
        )
      : null,
    racesEventsRef?.path,
  );

  return [
    raceMeeting,
    races?.sort((a, b) => a.number - b.number),
    raceMeetingLoading || racesLoading,
  ];
};
