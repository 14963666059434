import { getUserCredentials, revokeSession } from "./user";
import { collection, onSnapshot } from "firebase/firestore";
import { getFirestore } from "store/getFirebase";
import type { AccountVerificationDocumentType } from "types/VerificationTypes";

export type BrowserInfo = {
  browserJavascriptEnabled: boolean;
  browserJavaEnabled: boolean;
  browserColorDepth: string;
  browserLanguage: string;
  browserScreenHeight: string;
  browserScreenWidth: string;
  browserTZ: string;
  browserUserAgent: string;
} | null;

export type Payloads = {
  "generate-apple-pay-session": {
    validationUrl: string;
  };
  "update-password": {
    password: string;
    newPassword: string;
  };
  "generate-avatar-upload-url": null;
  "complete-user-profile": { phone: string };
  "confirm-avatar-upload": {
    // This is the response from external api that we just pass to our api directly
    asset_id: string;
    public_id: string;
    version: number;
    version_id: string;
    signature: string;
    width: number;
    height: number;
    format: string;
    resource_type: string;
    created_at: string;
    tags: string[];
    bytes: number;
    type: string;
    etag: string;
    placeholder: boolean;
    url: string;
    secure_url: string;
    folder: string;
    access_mode: string;
    overwritten: boolean;
    original_filename: string;
    api_key: string;
  };
  "confirm-avatar-delete": null;
  "claim-promotion-code": {
    code: string;
  };
  "submit-verification-data-update": {
    terms: boolean;
    type: AccountVerificationDocumentType;
    fields: {
      firstName: string;
      middleName: string;
      lastName: string;
      expiry: Date;
      dateOfBirth: Date;
      number: string;
      state?: string;
      versionNumber?: number;
      gender: "M" | "F";
    };
  };
  "resend-email-verification": {};
  "update-notification-preference": {
    [key: string]: string;
  };
  "update-email-subscription": {};
  "user-cooldown": {
    days: number;
  };
  "close-account": {};
  "update-deposit-limits": {
    maximumDailyDepositAmount: number;
    dailyLimitDays: number;
  };
  "dismiss-all-notifications": null;
  "dismiss-notification": {
    id: string;
  };
  "update-user-settings": {
    acceptOdds: string;
    oddsFormat: string;
    defaultHub: string;
    allowCancelWithdrawal: boolean;
  };
  "reopen-account": {
    maximumDailyDepositAmount?: number;
    dailyLimitDays?: number;
    responsibleGamblingMessageAccepted: boolean;
  };
  // TODO: need a better type for this, currently that's how it's defined in betslipSlice
  "betting-submit-slip": Record<string, any>;
  "skrill-deposit": {
    amount: number;
    returnUrl: string;
    returnUrlText: string;
    cancelUrl: string;
    logoUrl: string;
  };
  "mw-deposit-funds-3ds":
    | {
        amount: number;
        digitalWalletToken: string;
        digitalWalletType: "Google" | "Apple" | undefined;
        browserInfo: BrowserInfo;
      }
    | {
        amount: number;
        csc: string;
        cardId: string;
        browserInfo: BrowserInfo;
      };
  "create-au-bank-account": {
    bsb: string;
    name: string;
    number: string;
  };
  "cancel-withdrawal": {
    id: string;
  };
  "create-withdrawal": {
    amount: number;
    currency: string;
    bankAccountId: string;
    externalType: string;
  };
  "remove-bank-account": null;
  "mw-get-add-card-access-token": {
    numberFirst: string;
    numberLast: string;
    name: string;
    expiryMonth: string;
    expiryYear: string;
    returnUrl: string;
  };
  "mw-confirm-card": {
    cardId: string;
  };
  "verify-card": {
    code: string;
    id: string;
  };
  "mw-begin-verification": {
    id: string;
  };
  "mw-remove-card": {
    id: string;
  };
  "generate-user-pay-id": {};
  "get-deposit-limits": {};
  "validate-referral-code": {
    code: string;
    claimingOnSignup: boolean;
  };
  "pickems-submit-selection": {
    contestId: string;
    selections: any; // TODO: not worth copying it across atm, need to look into it later
    channel: string;
  };
  "get-bet-limits": {};
  "migrate-user-profile": {};
  "create-entry-share": {
    shareId: string;
  };
};

type Module = keyof Payloads;

type Options = {
  basicAuth?: {
    username: string;
    password: string;
  };
  ignoreErrors?: boolean;
  numResponses?: number;
  timeOutMs?: number;
};

type Response = {
  code: string;
  createdAt: Date;
  data?: any; // TODO: have stricter API responses
  displayText?: string;
  status: "SUCCESS" | "ERROR";
  errors?: [];
};

export type Result = [Response, ResultError | QueueError];
export type Results = [Response[], ResultError | QueueError];

const API_URI = process.env.GATSBY_API_URI;

class ResponseError extends Error {
  createdAt: Date;
  errors: { message: string; messageTemplate: string }[];
  code: string;

  constructor({
    createdAt,
    errors,
    code,
    displayText,
  }: {
    createdAt: Date;
    errors: { message: string; messageTemplate: string }[];
    code: string;
    displayText: string;
  }) {
    super(displayText);
    this.createdAt = createdAt;
    this.errors = errors;
    this.code = code;
  }
}

export class ResultError extends ResponseError {}
export class QueueError extends ResponseError {}

const sendMessage = async function <M extends Module>(
  module: M,
  payload?: Payloads[M],
  options?: Options,
): Promise<[Response, QueueError]> {
  const getHeaders = () => {
    let extraAuth: string;

    if (options?.basicAuth) {
      const { username, password } = options.basicAuth;
      extraAuth = ` Basic ${btoa(`${username}:${password}`)}`;
    }

    const { token } = getUserCredentials();

    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}${extraAuth ? ` ${extraAuth}` : ""}`,
    };
  };

  try {
    const response = await fetch(`${API_URI}/messages/${module}`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(payload ?? null),
    });

    const json = await response.json();

    if (response.ok) {
      return [
        {
          createdAt: new Date(),
          status: "SUCCESS",
          code: "queue-success",
          data: json,
        },
        null,
      ];
    }

    if (response.status === 401 || response.status === 403) {
      // User is not authorised, need to force logout
      await revokeSession();
    }

    // if we reached this point, something errored out, and we need to throw the error
    throw json;
  } catch (error) {
    return [
      null,
      new QueueError({
        createdAt: new Date(),
        errors: error.errors || [],
        code: "queue-error",
        displayText:
          error.errors?.[0]?.message ||
          `Infrastructure error when queuing message: ${error.message}`,
      }),
    ];
  }
};

const message = async function <M extends Module>(
  module: M,
  payload?: Payloads[M],
  options?: Omit<Options, "numResponses">,
): Promise<Result> {
  const [responses, error] = await messages(module, payload, {
    ...options,
    numResponses: 1,
  });

  if (error) {
    return [null, error];
  }

  return [responses[0], null];
};

const messages = async function <M extends Module>(
  module: M,
  payload?: Payloads[M],
  options?: Options,
): Promise<Results> {
  const [messageResponse, messageError] = await sendMessage(
    module,
    payload,
    options,
  );

  if (messageError) {
    return [null, messageError];
  }

  const generator = messageGenerator(
    messageResponse.data.messageId,
    options?.timeOutMs || 20000,
  );
  const numResponse = options?.numResponses || 1;

  // Keep all responses that we get from firestore till we get to number of responses we want
  const responses = [];

  const ignoreErrors = options?.ignoreErrors || false;

  try {
    for await (const response of generator) {
      if (response.status === "ERROR" && !ignoreErrors) {
        return [
          null,
          new ResultError({
            createdAt: response.createdAt,
            errors: response.errors || [],
            code: response.code,
            displayText: response.displayText,
          }),
        ];
      }

      responses.push(response);

      if (responses.length === numResponse) {
        return [responses, null];
      }
    }
  } catch (error) {
    return [
      null,
      new ResultError({
        createdAt: new Date(),
        errors: [error.message],
        code: "message-timeout",
        displayText: "Timed out waiting for a response",
      }),
    ];
  }
};

const watch = async function <M extends Module>(
  module: M,
  payload?: Payloads[M],
  options?: Options,
): Promise<
  [
    {
      next: () => Promise<Result>;
      messageId: string;
    },
    QueueError,
  ]
> {
  const [messageResponse, messageError] = await sendMessage(
    module,
    payload,
    options,
  );

  if (messageError) {
    return [null, messageError];
  }

  return [
    {
      next: createNextWatcher(messageResponse.data.messageId, options),
      messageId: messageResponse.data.messageId,
    },
    null,
  ] as const;
};

const createNextWatcher = (
  messageId: string,
  options?: Options,
): (() => Promise<Result>) => {
  const generator = messageGenerator(messageId, options?.timeOutMs || 20000);

  return async () => {
    try {
      const { value: response, done } = await generator.next();

      if (done || !response) {
        return [null, null];
      }

      if (response.status === "ERROR") {
        return [
          null,
          new ResultError({
            createdAt: response.createdAt,
            errors: response.errors || [],
            code: response.code,
            displayText: response.displayText,
          }),
        ];
      }

      return [response, null];
    } catch (error) {
      return [
        null,
        new ResultError({
          createdAt: new Date(),
          code: "message-timeout",
          displayText: "Timed out waiting for a response",
          errors: [],
        }),
      ];
    }
  };
};

async function* messageGenerator(
  messageId: string,
  timeOutMs = 20000, // defaults to 20 seconds
): AsyncGenerator<Response, void> {
  const { userId } = getUserCredentials();

  const messagesRef = collection(
    getFirestore(),
    "users",
    userId,
    "messageResponses",
    messageId,
    "responses",
  );

  const yieldedDocs = new Set<string>();

  // we will store documents that are not yielded yet in a queue
  const queue = [];

  let resolvePromise: (() => void) | null = null;

  const unsubscribe = onSnapshot(messagesRef, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added" && !yieldedDocs.has(change.doc.id)) {
        yieldedDocs.add(change.doc.id);
        const data = change.doc.data();
        queue.push({
          ...data,
          // convert timestamp to an actual date object
          createdAt: new Date(data.createdAt),
        });
      }

      // Resolve the promise if there are items in the queue
      if (resolvePromise && queue.length > 0) {
        resolvePromise();
      }
    });
  });

  try {
    while (true) {
      // If the queue is empty, wait for new items
      if (queue.length === 0) {
        await Promise.race([
          new Promise<void>((resolve) => (resolvePromise = resolve)),
          // we timeout response after 20s
          new Promise((_, reject) =>
            setTimeout(
              () => reject(new Error("Timed out waiting for response")),
              timeOutMs,
            ),
          ),
        ]);
      }

      // Yield items from the queue one by one
      while (queue.length > 0) {
        yield queue.shift()!;
      }
    }
  } finally {
    // once loop is finished or generator is closed, unsubscribe from the snapshot
    unsubscribe();
  }
}

export { message, watch, messages };
